  <template>
  <div class="promotions">
    <el-dialog :title="$t('Create Promotion')" :visible.sync="displayCreateForm" width="60%">
      <PromotionForm actionType="create" @action="createPromotion" />
    </el-dialog>
    <el-dialog :title="$t('Update Promotion')" :visible.sync="displayUpdateForm" width="60%">
      <PromotionForm actionType="update" @action="updatePromotion"/>
    </el-dialog>

    <div class="page-header">
      <h1>{{ $t('Promotions') }} > {{ $t('Promotions')}} </h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Promotions') }}</h2>
        <el-button class="action-button" type="primary" @click="showCreateForm"> {{ $t('Create Promotion') }}</el-button>
      </div>

      <ItemFilter :placeholder="$t('Search by promotion name')" :selection="false" />

      <div class="promotions-list">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('Promotion Name') }}</th>
              <th scope="col">{{ $t('Promotion Code') }}</th>
              <th scope="col">{{ $t('Promotion Type') }}</th>
              <th scope="col">{{ $t('Valid Date Range') }}</th>
              <th scope="col">{{ $t('Discount Value') }}</th>
              <th scope="col">{{ $t('Actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="promotion in promotions">
              <td>{{ promotion.promotion_name }}</td>
              <td>{{ promotion.promotion_code }}</td>
              <td>{{ promotion.promotion_type }}</td>
              <td>{{ formatDateTime(promotion.promotion_valid_date_start, 'YYYY-MM-DD') }} - {{ formatDateTime(promotion.promotion_valid_date_end, 'YYYY-MM-DD') }}</td>
              <td v-if="promotion.promotion_type === 'Percentage'">
                {{ promotion.promotion_value }}%
              </td>
              <td v-else>
                HK${{ promotion.promotion_value }}
              </td>
              <td>
                <div class="action-links">
                  <a class="action-link" @click="showUpdateForm(promotion.id)" href="#">
                    {{ $t('Edit') }}
                  </a>
                  <a class="action-link danger" href="#" @click="deletePromotion(promotion.id)">{{ $t('Delete') }}</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import ItemFilter from '@/components/Filter.vue';
import PromotionForm from '@/components/promotion/PromotionForm.vue';
import Common from '@/lib/common';
import Promotion from '@/lib/promotion';

export default {
  name: 'Promotions',
  components: {
    ItemFilter,
    PromotionForm,
  },
  mounted(){
    this.loadPromotions();
  },
  methods: {
    formatDateTime(timestamp, format){
      return Common.formatDateTime(timestamp, format);
    },
    async createPromotion(form){
      try{
        const promotionInfo = Object.assign({}, form);
        promotionInfo.promotionDate[0] = dayjs(promotionInfo.promotionDate[0]).unix();
        promotionInfo.promotionDate[1] = dayjs(promotionInfo.promotionDate[1]).unix();

        const loginInfo = Common.getLoginInfo();
        const promotion = await Promotion.createPromotion(this.apiUrl, promotionInfo, loginInfo);
        this.displayCreateForm = false;
      }catch(err){
        console.log(err);
      }
    },
    async updatePromotion(form, promotionId){
      try{
        const loginInfo = Common.getLoginInfo();
        const promotionInfo = Object.assign({}, form);
        promotionInfo.promotionDate[0] = dayjs(promotionInfo.promotionDate[0]).unix();
        promotionInfo.promotionDate[1] = dayjs(promotionInfo.promotionDate[1]).unix();
        const promotion = await Promotion.updatePromotion(this.apiUrl, promotionId, form, loginInfo);
        this.displayUpdateForm = false;
      }catch(err){
        console.log(err);
      }
    },
    async loadPromotion(promotionId){
      try{
        const loginInfo = Common.getLoginInfo();
        const promotion = await Promotion.loadPromotion(this.apiUrl, promotionId, loginInfo);
        this.setCurrentPromotion(promotion);
      }catch(err){
        console.log(err);
      }
    },
    async loadPromotions(){
      try{
        const loginInfo = Common.getLoginInfo();
        const promotions = await Promotion.loadAllPromotions(this.apiUrl, loginInfo);
        this.promotions = promotions;
        this.orignalPromotions = promotions;
      }catch(err){
        console.log(err);
      }
    },
    async deletePromotion(promotionId){
      try{
        const loginInfo = Common.getLoginInfo();
        await Promotion.deletePromotion(this.apiUrl, promotionId, loginInfo);
        const newPromotions = this.promotions.filter(function(item) {
          return item.id !== promotionId;
        });
        this.orignalPromotions = newPromotions;
        this.promotions = newPromotions;
      }catch(err){
        console.log(err);
      }
    },
    showCreateForm(){
      this.displayCreateForm = true;
    },
    showUpdateForm(promotionId){
      this.loadPromotion(promotionId);
      this.displayUpdateForm = true;
    },
    ...mapActions(['setCurrentPromotion']),
  },
  data(){
    return {
      activeName: 'All',
      displayCreateForm: false,
      displayUpdateForm: false,
      promotions: [],
      orignalPromotions: [],
    }
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
</style>
